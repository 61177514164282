import {useState} from 'react';
import moment from 'moment';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Debounce from '../components/Debounce';
import formatPhone from '../utils/formatPhone';

const CallerSearchForm = (props) => {

  const initialForm = {
    account_id: '',
    phone: '',
    name: '',
    company: '',
    purchase_id: ''
  }

  //Keep validation for this
  const [form, setForm] = useState(initialForm);

  const handleSubmit = (e, field) => {
    e.preventDefault();
    let value = form[field];
    
    if (field == 'account_id') {
      value = parseAccountId(form[field]);
    }

    props.search(value, field);
  }

  const parseAccountId = (account_id) => {
    while(account_id.charAt(0) == 0) {
      account_id = account_id.substring(1);
    }
    return account_id;
  }

  const onPhoneClick = (number) => {
    setForm({...form, phone: number});
    props.search(number, 'phone');
    props.setSearchedPhone(number);
  }
  
  return (
    <div>
      <SearchForm 
        name="Name" 
        search={(e) => handleSubmit(e, 'name')} 
        field={form.name}
        setField={(value) => setForm({...form, name: value})}
      />
      <SearchForm 
        name="Phone #" 
        search={(e) => handleSubmit(e, 'phone')} 
        field={form.phone}
        setField={(value) => setForm({...form, phone: value})}
      />
      <SearchForm 
        name="Account #" 
        search={(e) => handleSubmit(e, 'account_id')} 
        field={form.account_id}
        setField={(value) => setForm({...form, account_id: value})}
      />
      <SearchForm 
        name="Company" 
        search={(e) => handleSubmit(e, 'company')} 
        field={form.company}
        setField={(value) => setForm({...form, company: value})}
      />
      <SearchForm 
        name="Order #" 
        search={(e) => handleSubmit(e, 'purchase_id')} 
        field={form.purchase_id}
        setField={(value) => setForm({...form, purchase_id: value})}
      />
      <hr />
      <RecentCallList 
        calls={props.recentCalls} 
        addCall={(value) => onPhoneClick(value)}
      />
    </div>
  );
}

export default CallerSearchForm;

const SearchForm = (props) => {
  return (
    <Form onSubmit={props.search}>
      <Form.Row>
        <Col sm={8} className="my-1">
          <Form.Label htmlFor={"search_" + props.name} srOnly>
            {props.name}
          </Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{props.name}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl 
              id={"search_" + props.name}
              value={props.field}
              onChange={(e) => props.setField(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col sm={3}>
          <Button 
            variant="primary" 
            type="submit"
            style={{marginTop: '4px'}}
          >
            Search
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}


const RecentCallList = (props) => {
  const [_, rerender] = React.useState({});

  React.useEffect(() => {
    const interval = setInterval(() => {
      rerender({});
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const calls = props.calls.slice(0, 10);

  //Don't display this list if history is older than a day.
  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  const displayNoCalls = calls.length == 0 || calls[0].date < yesterday;

  return (
    <>
      <div style={{marginBottom: '10px', fontWeight: 'bold'}}>Recent Callers:</div>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {!displayNoCalls &&
            calls.map((call) => {
              return (
                <tr key={call.date}>
                  <td>{moment.utc(call.date, 'YYYY-MM-DD HH:mm:ss').fromNow()}</td>
                  <td>
                    {formatPhone(call.phone)}
                      <AddCircleOutlineIcon
                        onClick={() => props.addCall(call.phone)}
                        style={{
                          cursor: 'pointer',
                          marginLeft: 5
                        }}
                      />
                  </td>
                </tr>
              );
            })
          }
        {displayNoCalls &&
          <tr>
            <td style={{marginTop: '5px'}}>No Recent Calls</td>
          </tr>
        }
        </tbody>
      </Table>
    </>
  );
}
