import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { createCallAjax } from '../ajax/callsAjax';
import CallRating from './CallRating';
import { getOrderByRaAjax } from '../ajax/ordersAjax';
import { getOrderByDcAjax } from '../ajax/ordersAjax';

const reasonCategories = [
  'DC',
  'IT Issue',
  'Order',
  'Parts and Pricing',
  'RA',
  'Warranty',
  'Other'
];

const CallReasonForm = (props) => {

  //Ajax
  const [call, createCall, createCallMeta] = useAjax(createCallAjax, null);
  const [orderNumberByRa, getOrderByRa, getOrderByRaMeta] = useAjax(getOrderByRaAjax, null);
  const [orderNumberByDc, getOrderByDc, getOrderByDcMeta] = useAjax(getOrderByDcAjax, null);

  const initialFormFields = {
    category: 'Order',
    category_reference: '',
    rating: 0,
    rating_reason: ''
  };

  const [form, setForm] = useState({ ...initialFormFields });
  const [noteText, setNoteText] = useState('');
  const [error, setError] = useState('');
  const [shouldShowFrpLink, setShouldShowFrpLink] = useState(false);

  useEffect(() => {
    if (['RA', 'Order', 'DC'].includes(form.category)) {
      setShouldShowFrpLink(true);
    } else {
      setShouldShowFrpLink(false);
    }
  }, [form.category]);

  useEffect(() => {
    setError('');

    if (createCallMeta.failure) {
      setError(createCallMeta.message);
    }
  }, [createCallMeta.failure]);

  function getOrderNumberByReference() {
    if (form.category_reference) {
      if (form.category === 'RA') {
        getOrderByRa(form.category_reference);
      } else if (form.category === 'DC') {
        getOrderByDc(form.category_reference);
      }
    }
  }

  function buildFrpLink() {
    if (form.category === 'Order') {
      return process.env.NEXT_PUBLIC_FRP_URL + '/admin_purchase.php?action=view&purchase_id=' + form.category_reference
    } else if (form.category === 'RA' && Number.isInteger(parseInt(orderNumberByRa))) {
      return process.env.NEXT_PUBLIC_FRP_URL + '/admin_purchase.php?action=view&purchase_id=' + orderNumberByRa + '#anchor_ra_' + form.category_reference;
    } else if (form.category === 'DC' && Number.isInteger(parseInt(orderNumberByDc))) {
      return process.env.NEXT_PUBLIC_FRP_URL + '/admin_purchase.php?action=view&purchase_id=' + orderNumberByDc + '#anchor_dc_' + form.category_reference;
    }
  }

  function disableButton() {
    if (form.category === 'RA') {
      if (getOrderByRaMeta.error || !Number.isInteger(parseInt(orderNumberByRa)) || form.category_reference.length === 0) {
        return true;
      }
    } else if (form.category === 'DC') {
      if (getOrderByDcMeta.error || !Number.isInteger(parseInt(orderNumberByDc)) || form.category_reference.length === 0) {
        return true;
      }
    } else if (form.category === 'Order') {
      if (form.category_reference.length === 0) {
        return true;
      }
    }

    return false;
  }

  function showDisabledButtonReason() {
    if (getOrderByDcMeta.loading || getOrderByRaMeta.loading || getOrderByDcMeta.success || getOrderByRaMeta.success) {
      return null;
    }
    
    if (form.category === 'RA') {
      if (getOrderByRaMeta.error || !Number.isInteger(parseInt(orderNumberByRa)) && form.category_reference.length !== 0) {
        return (
          <p style={{ color: 'red', fontSize: '14px' }}>{form.category} not found</p>
        );
      }
    } else if (form.category === 'DC') {
      if (getOrderByDcMeta.error || !Number.isInteger(parseInt(orderNumberByDc)) && form.category_reference.length !== 0) {
        return (
          <p style={{ color: 'red', fontSize: '14px' }}>{form.category} not found</p>
        );
      }
    }

    return null;
  }

  const handleChange = (field, value) => {

    let newForm = { ...form };

    if (field == 'category' && !['DC', 'RA', 'Order'].includes(value)) {
      newForm.category_reference = '';
    }

    if (field == 'note') {
      setNoteText(value);
    } else if (field == 'category_reference') {

      if (Number.isInteger(parseInt(value)) || value == '') {
        newForm[field] = value == '' ? value : parseInt(value);
        setForm(newForm);
      }
    } else {
      newForm[field] = value;
      setForm(newForm);
    }
    
    setError('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const callData = {
      category: form.category,
      category_reference: form.category_reference ? form.category_reference : 0,
      rating: form.rating,
      rating_reason: form.rating_reason,
      rep_id: 0,
      caller_id: props.caller.id,
      note_text: noteText,
      note_reference_type: 'call'
    }
    
    const requiresReference = ['DC', 'RA', 'Order'].includes(callData.category);
    const hasRequiredReference = !requiresReference || (requiresReference && form.category_reference > 0);

    const requiresRatingReason = [1, 3].includes(parseInt(callData.rating));

    const hasRequiredRatingReason = !requiresRatingReason || (requiresRatingReason && form.rating_reason != '');
    
    if (callData.category && callData.rating && noteText && hasRequiredReference && hasRequiredRatingReason) {
      createCall(callData);
    } else {
      setError('*All fields are required');
    }
  }

  useEffect(() => {
    if (call) {
      props.setCall(call);
      props.nextStep();
    }
  }, [call]);

  function showCallReasonLink() {
    if (shouldShowFrpLink) {
      return (      
        <Row style={{marginTop: '10px'}}>
          <Col>
            <Button
              variant="link"
              size="sm"
              disabled={disableButton()}
              href={buildFrpLink()}
              target='_blank'
            >
              View {form.category}
            </Button>
            {showDisabledButtonReason()}
          </Col>
        </Row>
      );
    }
  }
  
  return (
    <Container fluid>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Call Category:</Form.Label>
            </Col>
            {['DC', 'RA', 'Order'].includes(form.category) && 
              <Col>
                <Form.Label>Reference Number:</Form.Label>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              <Form.Control
                as="select"
                name="category"
                value={form.category}
                onChange={(e) => handleChange('category', e.target.value)}
              >
                {reasonCategories.map((category) => {
                  return <option value={category}>{category}</option>
                })}
              </Form.Control>
            </Col>
            {['DC', 'RA', 'Order'].includes(form.category) &&
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Reference #"
                  name="category_reference"
                  value={form.category_reference}
                  onChange={(e) => handleChange('category_reference', e.target.value)}
                  onBlur={() => {
                    if (form.category_reference && form.category === 'Order' || form.category === 'RA' || form.category === 'DC') { 
                      setShouldShowFrpLink(true) 
                    }

                    getOrderNumberByReference();
                  }}
                />
              </Col>
            }

          </Row>
          {showCallReasonLink()}
          <Row style={{marginTop: '10px'}}>
            <Col>
              <Form.Label>Notes:</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Notes..."
                name="note"
                value={noteText}
                onChange={(e) => handleChange('note', e.target.value)}
                maxLength={100}
              />
            </Col>
          </Row>
          <Row>
            <CallRating 
              setRating={(value) => handleChange('rating', value)}
              selectedRating={form.rating}
            />
          </Row>
          {[1, 3].includes(form.rating) &&
            <>
              <Row style={{marginTop: '10px'}}>
                <Col>
                  <Form.Label>Reason for this rating:</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control 
                    value={form.rating_reason}
                    onChange={(e) => handleChange('rating_reason', e.target.value)}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </>
          }
        </Form.Group>
        <Button 
          style={{marginRight: '10px'}} 
          onClick={props.reset}
        >
          Back
        </Button>
        <Button type="submit">
          Submit
        </Button>
        <div style={{color: 'red'}}>{error}</div>
      </Form>
    </Container>
  )
}

export default CallReasonForm;
