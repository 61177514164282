import CallerSearchPage from '../components/CallerSearchPage';
import CallerDataPage from '../components/CallerDataPage';
import SuccessPage from '../components/SuccessPage';
import Layout from '../components/Layout';
import { useState } from 'react';
import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { searchAccountsAjax } from '../ajax/accountsAjax';
import { searchCallersAjax } from '../ajax/callersAjax';
import { useCallHistory } from '../modules/CallHistory/useCallHistory';

export default function Home() {
  const [step, setStep] = useState('callerSearch');
  const [call, setCall] = useState(null);
  const [caller, setCaller] = useState(null);
  const [account, setAccount] = useState(null);
  const recentCalls = useCallHistory();

  const [accounts, searchAccounts, searchAccountsMeta] = useAjax(searchAccountsAjax, []);
  const [callers, searchCallers, searchCallersMeta] = useAjax(searchCallersAjax, []);

  const resetValues = () => {
      setStep('callerSearch');
      setCall(null);
      setCaller(null);
      setAccount(null);
  }

  return (
    <Layout>
      {(step == 'callerSearch') &&
        <CallerSearchPage 
          setCaller={setCaller}
          setAccount={setAccount}
          nextStep={() => setStep('displayCallerData')}
          accounts={accounts}
          searchAccounts={searchAccounts}
          searchAccountsMeta={searchAccountsMeta}
          callers={callers}
          recentCalls={recentCalls}
          searchCallers={searchCallers}
          searchCallersMeta={searchCallersMeta}
        />
      }
      {(step == 'displayCallerData') &&
        <CallerDataPage 
          account={account}
          caller={caller}
          setCall={setCall}
          nextStep={() => setStep('end')}
          reset={resetValues}
        />
      }
      {(step == 'end') &&
        <SuccessPage 
          reset={resetValues}
          call={call}
        />
      }
    </Layout>
  )
}