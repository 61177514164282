import ajax from './common.js';
import { prefix } from './common.js';

export async function createCallerByReferenceAjax(data) {
  return await ajax({
    method: 'POST',
    url: prefix + '/callers/by-reference',
    data
  });
}

export async function createCallerByPhoneAjax(data) {
  return await ajax({
    method: 'POST',
    url: prefix + '/callers/by-reference',
    data: {
      reference_type: 'phone',
      reference_value: data.phone,
      first_name: data.first_name,
      last_name: data.last_name
    }
  });
}

export async function getCallerCallsAjax(id) {
  return await ajax({
    url: prefix + '/callers/' + id + '/calls'
  })
}

export async function searchCallersAjax(searchTerm, field) {
  return await ajax({
    url: prefix + '/callers/search',
    data: {searchTerm, field}
  })
}

export async function updateCallerAjax(id, data) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/callers/' + id,
    data
  });
}
