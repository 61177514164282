import {useState} from 'react';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { getCallNotesAjax } from '../ajax/callsAjax';

const style = {
  th: {
    position: 'sticky', 
    top: 0,
    backgroundColor: 'white',
    zAxis: 1000
  }
}

const CallList = (props) => {

  const [notes, getNotes, getNotesMeta] = useAjax(getCallNotesAjax, []);
  const [selectedCall, setSelectedCall] = useState(0);

  const displayNotes = (id) => {
    setSelectedCall(id);
    getNotes(id);
  }

  function displayRatingJustification(call) {
    if (call.rating_reason) {
      return (
        <Alert variant='dark'><strong>Rating Justification: </strong>{call.rating_reason}</Alert>
      );
    }
  }

  return (
    <div style={{overflow: 'auto', height: '650px'}}>
      <Table bordered hover>
        <thead>
          <tr>
            <th style={style.th}>Call Date</th>
            {props.isAccount && <th style={style.th}>Caller</th>}
            <th style={style.th}>Category</th>
            <th style={style.th}>Ref ID</th>
            <th style={style.th}>Rating</th>
            <th style={style.th}>Rep</th>
          </tr>
        </thead>
        <tbody>
          {props.calls.map((call) => {
            
            //To prevent the disruptive flash between the old note closing and awaiting the new note,
            //keep displaying the old note until the new note is loaded.
            //TODO:: Include notes with the calls so that this can be removed.
            const displayStaleNote = notes.length > 0 && notes[0].reference_id == call.id && getNotesMeta.loading;
            const displayNewNote = !getNotesMeta.loading && call.id == selectedCall;
            const displayNote = displayStaleNote || displayNewNote;

            return (
              <>
                <tr 
                  style={call.id == selectedCall ? {backgroundColor: '#cce5ff'} : {cursor: 'pointer'}}
                  onClick={() => displayNotes(call.id)}
                >
                  <td>{call.date}</td>
                  {props.isAccount && <td>{call.caller.first_name + ' ' + call.caller.last_name}</td>}
                  <td>{call.category}</td>
                  <td>{call.category_reference == 0 ? '' : call.category_reference}</td>
                  <td>{call.rating}</td>
                  <td>{call.rep ? call.rep.first_name + ' ' + call.rep.last_name : ''}</td>
                </tr>
                {displayNote &&
                  <tr style={{backgroundColor: 'white'}}>
                    {notes.length > 0 ?
                      <td colSpan={props.isAccount ? 6 : 5}>
                        {notes.map((note) => {
                          return <Alert variant='dark'>{note.text}</Alert>
                        })}
                        {displayRatingJustification(call)}
                      </td>
                    :
                      <td colSpan={props.isAccount ? 6 : 5} style={{textAlign: 'center'}}>
                        No Notes
                        {displayRatingJustification(call)}
                      </td>
                    }
                  </tr>
                }
              </>
            )
          })}
        </tbody>
      </Table>
      {/* <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Button style={{width: '100px'}}>See all</Button>
      </div> */}
      {props.calls.length == 0 &&
        <div>No Call History</div>
      }
    </div>
    );
}

export default CallList;