import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';

const PromptAlert = ({prompt}) => {

  const [show, setShow] = useState(true);

  return (
    <Col style={{ marginTop: '15px' }}>
      <Alert show={show} variant="info">
        <p>{prompt}</p>
        <div>
          <Button onClick={() => setShow(false)} variant="outline-info">
            Prompt Completed
          </Button>
        </div>
      </Alert>
    </Col>
  )
}

export default PromptAlert;
