import ajax from './common.js';
import { prefix } from './common.js';

export async function searchAccountsAjax(searchTerm, field) {
  return await ajax({
    url: prefix + '/callers/accounts',
    data: {searchTerm, field}
  });
}

export async function getAccountCallsAjax(id) {
  return await ajax({
    url: prefix + '/account/' + id + '/calls'
  })
}

export async function getAccountAjax(id) {
  return await ajax({
    url: prefix + '/account/' + id
  })
}