import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { createCallerByPhoneAjax } from '../ajax/callersAjax';
import Button from 'react-bootstrap/Button';
import {useState, useEffect} from 'react';

const style = {
  label: {
    width: '100px',
  },
  field: {
    width: '145px',
    display: 'inline-block',
    marginRight: '20px'
  }
}

const AddCallerForm = (props) => {

  const [caller, createCaller] = useAjax(createCallerByPhoneAjax, null);

  const initialForm = {
    first_name: '',
    last_name: '',
    phone: ''
  }

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (props.order && props.accounts.length === 0) {
      setForm({
        first_name: props.order.purchase_card_name?.split(' ')[0],
        last_name: props.order.purchase_card_name?.split(' ')[1],
        phone: props.order.purchase_phone
      })
    } else if (props.order && props.accounts.length > 0) {
      setForm({
        first_name: '',
        last_name: '',
        phone: ''
      })
    }
  }, [props.order, props.loading, props.accounts])

  const handleChange = (field, value) => {
    let newForm = {...form};
    if (field == 'phone') {
      const numericPhone = value.replace(/\D/g, '');

      if ((Number.isInteger(parseInt(numericPhone)) && numericPhone.length <= 10) || value == '') {
        newForm[field] = numericPhone == '' ? numericPhone : parseInt(numericPhone);
      }
    } else {
      newForm[field] = value;
    }
    setForm(newForm);
  }

  const maskPhone = () => {
    let phone = String(form.phone);
    let phoneDisplay = phone;

    if (phone.length > 3) {
      phoneDisplay = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6);

      if (phone.length > 6) {
        phoneDisplay += '-' + phone.substring(6, 10);
      }
    }
    return phoneDisplay;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    createCaller(form);
  }

  useEffect(() => {
    if (caller) {
      props.setCaller(caller);
      props.nextStep();
    }
  },[caller]);

  useEffect(() => {
    setForm({...form, phone: props.searchedPhone});
  },[props.searchedPhone]);

  const emptyForm = form.first_name == '' || form.last_name == '' || form.phone == '';

  return (
    <>
      <form 
        onSubmit={onSubmit}
        style={{marginTop: '15px'}}
      >
        <label style={style.label}>First name:</label>
        <input 
          style={style.field}
          type="text" 
          className="form-control" 
          value={form.first_name} 
          onChange={(e) => handleChange('first_name', e.target.value)}
        />
        <label style={style.label}>Last name:</label>
        <input 
          style={style.field}
          type="text" 
          className="form-control" 
          value={form.last_name} 
          onChange={(e) => handleChange('last_name', e.target.value)}
        />
        <label style={{width: '75px'}}>Phone:</label>
        <input 
          style={style.field}
          type="text" 
          className="form-control" 
          placeholder='(XXX) XXX-XXXX'
          value={maskPhone(form.phone)} 
          onChange={(e) => handleChange('phone', e.target.value)}
        />
        <Button
          style={{marginLeft: '20px', verticalAlign: 'top'}}
          type="submit"
          disabled={emptyForm}
        >
          Add Caller
        </Button>
      </form>
    </>
  );
}

export default AddCallerForm;