import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { getCallerCallsAjax } from '../ajax/callersAjax';
import { getCallPromptsAjax } from '../ajax/callsAjax';
import { getAccountCallsAjax } from '../ajax/accountsAjax';
import CallReasonForm from './CallReasonForm';
import AccountDataDisplay from './AccountDataDisplay';
import CallList from './CallList';
import {useEffect} from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const CallerDataPage = (props) => {

  //Ajax
  const [calls, getCalls] = useAjax(getCallerCallsAjax, []);
  const [accountCalls, getAccountCalls] = useAjax(getAccountCallsAjax, []);
  const [prompts, getPrompts] = useAjax(getCallPromptsAjax, []);

  const isAccount = props.caller.reference_type == 'account';

  const getTotalRating = (list) => {
    return list.reduce((count, call) => {
      return count + call.rating;
    }, 0);
  }

  const averageAccountRating = accountCalls.length > 0 ? (getTotalRating(accountCalls) / accountCalls.length).toFixed(1) : null;
  const averageNonAccountRating = calls.length > 0 ? (getTotalRating(calls) / calls.length).toFixed(1) : null;

  useEffect(() => {
    if (isAccount) {
      getAccountCalls(props.caller.reference_value);

    } else {
      getCalls(props.caller.id);
    }
  },[]);

  return (
    <div>
      <Row>
        <Col md={3}>
          <CallReasonForm 
            nextStep={props.nextStep}
            reset={props.reset}
            caller={props.caller}
            setCall={props.setCall}
            setNote={props.setNote}
          />
        </Col>
        <Col>
          <CallList 
            calls={isAccount ? accountCalls : calls}
            isAccount={isAccount}
          />
        </Col>
        <Col>
          <AccountDataDisplay
            account={props.account}
            caller={props.caller}
            getPrompts={getPrompts}
            prompts={prompts} 
            averageRating={props.account ? averageAccountRating : averageNonAccountRating}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CallerDataPage;