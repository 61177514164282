import Button from '@material-ui/core/Button';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

const SuccessPage = (props) => {

  return (
    <>
      <h4 style={{marginTop: '25px'}}>Success! The record for this call has been saved.</h4>
      <CallRecord 
        call={props.call} 
        notes={[props.note]}
      />
      <Button
        variant="contained"
        color="primary"
        style={{marginTop: '5px'}}
        onClick={() => props.reset()}
      >
        New Call
      </Button>
    </>
  );
}

export default SuccessPage;

const CallRecord = ({call, notes}) => {
  return (
    <div style={{width: '750px', marginTop: '25px'}}>
      <Table>
        <thead>
          <tr>
            <th>Call Date</th>
            <th>Category</th>
            <th>Ref ID</th>
            <th>Rating</th>
            <th>Site</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{call.date}</td>
            <td>{call.category}</td>
            <td>{call.category_reference}</td>
            <td>{call.rating}</td>
            <td>{call.site}</td>
          </tr>
        </tbody>
      </Table>
      {call.notes.map((note) => {
        return (
          <Alert 
           variant='dark'
          >
            {note.text}
          </Alert>
        )
      })}
    </div>
  );
}