import ajax from './common.js';
import { prefix } from './common.js';

export async function getOrderAjax(id) {
    return await ajax({
      url: prefix + '/orders/' + id
    });
}

export async function getOrderByRaAjax(id) {
  return await ajax({
    url: prefix + '/orders/ra/' + id
  });
}

export async function getOrderByDcAjax(id) {
  return await ajax({
    url: prefix + '/orders/damage-claims/' + id
  });
}