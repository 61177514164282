import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useAjax } from '@developers/frp-library/Hooks/useAjax';
import { createCallerByReferenceAjax } from '../ajax/callersAjax';
import { getOrderAjax } from '../ajax/ordersAjax';
import CallerSearchForm from './CallerSearchForm';
import AddCallerForm from './AddCallerForm.js';
import formatPhone from '../utils/formatPhone';

const CallerSearchPage = (props) => {

  const initialNameForm = {
    firstName: '',
    lastName: ''
  };

  const [nameForm, setNameForm] = useState({ ...initialNameForm });
  const [selectedAccount, setSelectedAccount] = useState(null);
  
  //Ajax
  const [newCaller, createCaller] = useAjax(createCallerByReferenceAjax, null);
  const [order, getOrder, getOrderMeta] = useAjax(getOrderAjax, null);

  const [tab, setTab] = useState('Non-Account');
  const [callerExists, setCallerExists] = useState(false);
  const [searchedPhone, setSearchedPhone] = useState('');

  const handleAccountUserSubmit = (e) => {
    e.preventDefault();
    addNewCaller();
  }

  const addNewCaller = (first = null, last = null) => {
    const callerData = {
      first_name: first ? first : nameForm.firstName,
      last_name: last ? last : nameForm.lastName,
      reference_type: 'account',
      reference_value: selectedAccount.account_id
    }
    createCaller(callerData);
  }

  const addExistingCaller = (caller) => {
    props.setCaller(caller);
    setCallerExists(true);
  }

  useEffect(() => {
    if (newCaller) {
      props.setAccount(selectedAccount);
      props.setCaller(newCaller);
      props.nextStep();
    }

    if (callerExists) {
      props.nextStep();
    }
  },[newCaller, callerExists]);

  const changeTab = (newTab) => {
    if (newTab == 'Non-Account') {
      setSelectedAccount(null);
    }
    setTab(newTab);
  }

  const search = (searchTerm, field) => {
    props.searchCallersMeta.reset();
    props.searchCallers(searchTerm, field);

    props.searchAccountsMeta.reset();
    props.searchAccounts(searchTerm, field);

    getOrderMeta.reset();
    getOrder(searchTerm);

    setSelectedAccount(null);
  }
  
  return (
    <Container fluid>
      <Row>
        <Col md={4}>
          <div style={{marginBottom: '10px', fontWeight: 'bold'}}>Search Callers:</div>
          <CallerSearchForm 
            search={search}
            recentCalls={props.recentCalls}
            setSearchedPhone={setSearchedPhone}
          />
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '10px', fontWeight: 'bold'}}>Caller Results:</div>
          <Tabs 
            defaultActiveKey='Non-Account'
            activeKey={tab}
            onSelect={(value) => changeTab(value)}
            transition={false} 
          >
            <Tab 
              eventKey="Non-Account" 
              title={
                <TabHeader 
                  label='Non-Account'
                  total={props.callers.length}
                  selected={tab}
                  loading={props.searchCallersMeta.loading}
                />
              }
            >
              <PreviousCallersResults 
                order={order}
                accounts={props.accounts}
                callers={props.callers}
                setCall={props.setCall}
                setCaller={props.setCaller}
                nextStep={props.nextStep}
                addExistingCaller={addExistingCaller}
                loading={props.searchCallersMeta.loading || getOrderMeta.loading}
                error={props.searchCallersMeta.failure}
                searchedPhone={searchedPhone}
              />
            </Tab>
            <Tab 
              eventKey="Account" 
              title={
                <TabHeader
                  label='Account' 
                  total={props.accounts.length}
                  selected={tab}
                  loading={props.searchAccountsMeta.loading}
                />
              }
            >
              <AccountsResults 
                accounts={props.accounts}
                setSelectedAccount={setSelectedAccount}
                selectedId={selectedAccount ? selectedAccount.account_id : 0}
                loading={props.searchAccountsMeta.loading}
                error={props.searchAccountsMeta.failure}
              />
            </Tab>
          </Tabs>
        </Col>

        <Col md={2}>
          <UsersList
            selectedAccount={selectedAccount} 
            handleUserClick={addNewCaller}
          />
          {selectedAccount &&
            <AddUserToAccountForm 
              nameForm={nameForm}
              setNameForm={setNameForm}
              handleAccountUserSubmit={handleAccountUserSubmit}
            />
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CallerSearchPage;

const TabHeader = (props) => {

  if (props.selected == props.label) {
    return props.label;
  }

  return (
    <div>{props.label + ' '}
      {props.loading ?
        <Spinner 
          animation="border" 
          variant="primary" 
          size="sm"
        />
      :
        <Badge variant="primary">
          {props.total > 0 ? props.total : ''}
        </Badge>
      }
    </div>
  );
}

const PreviousCallersResults = (props) => {
  const noResults = props.callers.length == 0;

  if (props.error) {
    return <div style={{margin: '10px', color: 'red'}}>Failed retrieving callers</div>
  }

  return (
    <>
      <AddCallerForm 
        order={props.order}
        accounts={props.accounts}
        loading={props.loading}
        setCall={props.setCall}
        setCaller={props.setCaller}
        nextStep={props.nextStep}
        searchedPhone={props.searchedPhone}
      />
      <div style={{height: '575px', overflow: 'auto'}}>
        <Table style={{marginTop: '15px'}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
          {props.callers.map((caller) => {
            return (
              <tr key={caller.id}>
                <td key={caller.id}>{caller.first_name + ' ' + caller.last_name}</td>
                <td>
                  {caller.reference_type == 'phone' && 
                    <>
                      {formatPhone(caller.reference_value)}
                      <AddCircleOutlineIcon
                        onClick={() => props.addExistingCaller(caller)}
                        style={{
                          cursor: 'pointer',
                          marginLeft: 5
                        }}
                      />
                    </>
                  }
                </td>
              </tr>
            );
          })}
          {(noResults || props.loading) &&
            <tr>
              <td colSpan={2}>{props.loading ? 'Loading...' : 'No Results'}</td>
            </tr>
          }
          </tbody>
        </Table>
      </div>
    </>
  );
}

const AccountsResults = (props) => {

  const formatAccountId = (id) => {
    let formattedId = String(id);

    while (formattedId.length < 6) {
      formattedId = '0' + formattedId;
    }
    return formattedId;
  }

  if (props.error) {
    return <div style={{margin: '10px', color: 'red'}}>Failed retrieving accounts</div>
  }

  if (props.accounts.length == 0 || props.loading) {
    return <div style={{margin: '10px'}}>{props.loading ? 'Loading...' : 'No Results'}</div>
  }

  return (
    <ListGroup 
      style={{height: '600px', overflow: 'auto'}}
      activeKey={props.selectedId}
    >
      {props.accounts.map((account) => {
        return (
          <ListGroup.Item 
            action 
            eventKey={account.account_id}
            key={account.account_id}
            onClick={() => props.setSelectedAccount(account)}
          >
            <Row>Account Number: {formatAccountId(account.account_id)}</Row>
            <Row>Company Name: {account.company_name}</Row>
          </ListGroup.Item>
        )
      })}
    </ListGroup> 
  );
}

const UsersList = (props) => {

  if (!props.selectedAccount) {
    return null;
  }

  return (
    <>
      <div style={{marginBottom: '10px', fontWeight: 'bold'}}>Users:</div>
      <div  style={{height: '200px', overflow: 'auto', marginTop: '10pxs'}}>
        {props.selectedAccount.users.map((user) => {
          return (
            <div>
              {user.first_name} {user.last_name}
              <AddCircleOutlineIcon
                onClick={() => props.handleUserClick(user.first_name, user.last_name)}
                style={{
                  cursor: 'pointer',
                  marginLeft: 5
                }}
              />
              < br />
            </div>
          );
        })}
      </div>
    </>
  );
}

const AddUserToAccountForm = (props) => {
  return (
    <div style={{marginTop: '25px'}}>
      <strong>Create Caller From Account:</strong>
      <Form onSubmit={props.handleAccountUserSubmit}>
        <Form.Label>First name:</Form.Label>
        <Row>
          <Col md="auto">
            <Form.Control
              name="firstName"
              type="text"
              value={props.nameForm.firstName}
              onChange={(e) => props.setNameForm({...props.nameForm, firstName: e.target.value})}
              placeholder="Enter first name"
            />
          </Col>
        </Row>
        <Form.Label>Last name:</Form.Label>
        <Row>
          <Col md="auto">
            <Form.Control
              name="firstName"
              type="text"
              value={props.nameForm.lastName}
              onChange={(e) => props.setNameForm({...props.nameForm, lastName: e.target.value})}
              placeholder="Enter last name"
            />
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: 10 }}
              disabled={props.nameForm.firstName == '' || props.nameForm.lastName == ''}
            >
              Add Caller
          </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
