import {useState} from 'react';
import StarIcon from '@material-ui/icons/Star';

const CallRating = (props) => {

  const [ratings, setRatings] = useState([
    { value: 1, color: '#f0f0f0' },
    { value: 2, color: '#f0f0f0' },
    { value: 3, color: '#f0f0f0' },
  ]);

  function handleClick(clickedRating) {
    props.setRating(clickedRating.value);
    
    let updatedRatings = [...ratings];

    updatedRatings.map((rating) => {
      if (rating.value <= clickedRating.value) {
        rating.color = '#f2ef50';
      } else {
        rating.color = '#f0f0f0';
      }

      return rating;
    });

    setRatings(updatedRatings);
  }

  function onHover(hoveredRating) {    
    let updatedRatings = [...ratings];

    updatedRatings.map((rating) => {
      if (props.selectedRating !== hoveredRating.value && hoveredRating.value > props.selectedRating) {
        if (hoveredRating.value === rating.value) {
          rating.color = '#fffe78';
        }
      }

      return rating;
    });

    setRatings(updatedRatings);
  }

  function onLeave(hoveredRating) {
    let updatedRatings = [...ratings];

    updatedRatings.map((rating) => {
      if (props.selectedRating !== hoveredRating.value && hoveredRating.value > props.selectedRating) {
        if (hoveredRating.value === rating.value) {
          rating.color = '#f0f0f0';
        }
      }

      return rating;
    });

    setRatings(updatedRatings);
  }
  
  return (
    <div style={{width: '150px', margin: '10px', cursor: 'pointer'}}>
      Rate Call: <br />
        {ratings.map((rating, index) => {
          return (
            <StarIcon
              key={rating.value}
              style={{ fontSize: '32px', color: rating.color }}
              onClick={() => handleClick(rating)}
              onMouseOut={(e) => onLeave(rating)}
              onMouseOver={(e) => onHover(rating)}
            />
          );
        })}
    </div>
  );
}

export default CallRating;

