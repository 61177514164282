import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import PromptAlert from './PromptAlert';
import moment from 'moment';
import {useEffect} from 'react';
import formatPhone from '../utils/formatPhone';
import { formatMs } from '@material-ui/core';

const ratings = [ 
  {value: 1, color: '#f44336', textColor: '#730000'},
  {value: 2, color: '#ffeb3b', textColor: '#8A9000'},
  {value: 3, color: '#4caf50', textCOlor: '#2D4F01'}
];

const AccountDataDisplay = (props) => {

  useEffect(() => {
    if (props.account) {
      props.getPrompts(props.account.account_id);
    }
  }, []);

  function calculateAccountAge(startDate) {
    let today = new Date();
    let startArray = moment(startDate).format('l').split('/');
    let todayArray = moment(today).format('l').split('/');

    let yearsDiff = todayArray[2] - startArray[2];
    let monthsDiff = todayArray[0] - startArray[0];

    let accountAge = '';
    if (yearsDiff > 0 && monthsDiff < 0) {
      let months = (12 - startArray[0]) + +todayArray[0];
      accountAge = yearsDiff + ' years ' + months + ' months';
    } else if (yearsDiff > 0 && monthsDiff > 0) {
      accountAge = yearsDiff + ' years ' + monthsDiff + ' months';
    } else if (yearsDiff > 0) {
      accountAge = yearsDiff + ' years';
    } else if (monthsDiff > 0) {
      accountAge = monthsDiff + ' months';
    }
    
    return accountAge;
  }

  const selectedUserName = props.caller.first_name + ' ' + props.caller.last_name;

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Card>
        <Card.Header as="h5">
          {props.account ? props.account.company_name : selectedUserName}
          <p style={{ fontSize: '16px', paddingTop: 10, color: '#aaa' }}>{props.account && props.account.company_category}</p>
          {props.account && <a href={process.env.NEXT_PUBLIC_FRP_URL + '/account.php?action=editaccount&account_id=' + props.account.account_id} target="_blank"><h6>#{props.account.account_id}</h6></a>}
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Phone</th>
                {props.account && <th>Account Age</th>}
                {props.account && <th>Status</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{props.caller.reference_type == 'phone' ? formatPhone(props.caller.reference_value) : formatPhone(props.account.billing_phone)}</td>
                {props.account && <td>{calculateAccountAge(props.account.start_date)}</td>}
                {props.account && <td>{props.account.status}</td>}
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                {props.account &&
                  <>
                    <th>On Terms</th>
                    <th>Return Ratio</th>
                    <th>Order Total (365 days)</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {props.account &&
                  <>
                    <td>{props.account.terms !== 'COD/Credit Card' ? 'Yes' : 'No'}</td>
                    <td>{Math.round(props.account.return_ratio * 100) + '%'}</td>
                    <td>${props.account.terms_order_amount_progress}</td>
                  </>
                }
              </tr>
            </tbody>
          </Table>
          {props.account &&
            <>
              <div style={{marginBottom: '5px', fontWeight: 'bold'}}>Users:</div>
              <div style={{maxHeight: '250px', overflow: 'auto'}}>
                <ListGroup>
                  {props.account.users.map((user) => {
                    const name = user.first_name + ' ' + user.last_name;

                    return (
                      <ListGroup.Item style={name == selectedUserName ? {backgroundColor: '#007BFF', color: 'white'} : null}>
                        {user.first_name} {user.last_name}
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </div>
            </>
          }
        </Card.Body>
      </Card>
      <RatingWidget 
        rating={props.averageRating}
      />
      {props.prompts.map((prompt) => {
        return <PromptAlert prompt={prompt} />
      })}
    </Container>
  )
}

export default AccountDataDisplay;

const RatingWidget = (props) => {

  const ratingColor = ratings.reduce((color, rating) => {
    if (props.rating >= rating.value) {
      return rating;
    } else {
      return color;
    }
  }, {color: '#f7f7f7', textColor: 'none'});

  return (
    <div 
      style={{
        backgroundColor: ratingColor.color, 
        color: ratingColor.textColor, 
        padding: '10px', 
        borderRadius: '10px', 
        marginTop: '15px', 
        width: '50%'}}
      >
      <h4>Average Rating: {props.rating ? props.rating : 'N/A'}</h4>
    </div>
  )
}
